import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import 'react-sortable-tree/style.css';
import './App.scss';
import Cookies from 'js-cookie';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Login
const Login = Loadable({
  loader: () => import('./app/user/views/Login'),
  loading
});
// Remember Password
const RememberPassword = Loadable({
  loader: () => import('./app/user/views/RememberPassword'),
  loading
});

// const Register = Loadable({
//   loader: () => import('./views/Pages/Register'),
//   loading
// });

const Page404 = Loadable({
  loader: () => import('./app/pages/Page404'),
  loading
});

const IdGenerator = Loadable({
  loader: () => import('./app/others/IdGenerator/views/IdGenerator'),
  loading
});

// const Page500 = Loadable({
//   loader: () => import('./views/Pages/Page500'),
//   loading
// });

// Containers
// import { DefaultLayout } from './containers';
// Pages
// import {Page404, Page500} from "./views/Pages";

// import Login, {fakeAuth} from "./app/user/views/Login";
// import {Login, Register} from "./app/user/views";

const PrivateRoute = ({component: Component, authed, ...rest}) => {


  // get current user
  let token = Cookies.get('token');
  return (
      <Route
          {...rest}
          render={(props) => (authed === true || token)
              ? <Component {...props} />
              : <Redirect to={{pathname: '/login', state: {from: props.location}}} />} />
  )
}

class App extends Component {

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/remember-password" name="Remember Password" component={RememberPassword} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          {/* <Route exact path="/500" name="Page 500" component={Page500} /> */}
          <Route exact path="/shenase" name="Page 500" component={IdGenerator} />
          <PrivateRoute authed={false} path='/' component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;


/*
 <Route exact path="/register" name="Register Page" component={Register}/>
 <Route exact path="/404" name="Page 404" component={Page404}/>
 <Route exact path="/500" name="Page 500" component={Page500}/>
 <Route path="/" name="Home" component={DefaultLayout}/>
* */
